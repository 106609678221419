@font-face {
font-family: 'geistSans';
src: url(/_next/static/media/4473ecc91f70f139-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: 'geistSans Fallback';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_1e4310 {font-family: 'geistSans', 'geistSans Fallback'
}.__variable_1e4310 {--font-geist-sans: 'geistSans', 'geistSans Fallback'
}

@font-face {
font-family: 'geistMono';
src: url(/_next/static/media/463dafcda517f24f-s.p.woff) format('woff');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: 'geistMono Fallback';src: local("Arial");ascent-override: 69.97%;descent-override: 16.73%;line-gap-override: 7.61%;size-adjust: 131.49%
}.__className_c3aa02 {font-family: 'geistMono', 'geistMono Fallback'
}.__variable_c3aa02 {--font-geist-mono: 'geistMono', 'geistMono Fallback'
}

